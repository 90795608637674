import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Flex } from 'ui-base/flex/Flex'
import { Truncate } from 'ui-base/truncate/Truncate'
import { ProductLogoAvatar } from 'ui-shared/productLogoAvatar/ProductLogoAvatar'

export const TableWrapper = styled(Flex)`
  min-height: 300px;
  height: 100%;
  width: 100%;
`

export const ProductLogo = styled(ProductLogoAvatar)`
  flex-shrink: 0;
`

export const NameColumnInner = styled(Flex)`
  min-width: 0;
`

export const ProductName = styled(Truncate)`
  height: 22px;
`

export const subtitleStyles = css`
  &::part(typography) {
    color: var(--wpp-grey-color-800);
  }
`

export const ProductCategory = styled(Truncate)`
  height: 20px;

  ${subtitleStyles}
`

export const ActionsWrapper = styled(Flex)`
  height: 100%;
  position: static;
  flex-shrink: 0;
  min-width: 0;
`

export const Circle = styled('div')`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px dashed;
  border-color: inherit;
  min-width: 0;
`

export const CircleWrapper = styled(Flex)`
  width: 20px;
  height: 20px;
`
