import { ListItemChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppListItemCustomEvent } from '@platform-ui-kit/components-library/loader'
import { WppSkeleton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { useCollectionData } from 'hooks/useCollectionData'
import { CollectionDetailsTabs } from 'pages/collections/details/CollectionDetailsPage'
import { Flex } from 'ui-base/flex/Flex'
import { RoutesManager } from 'utils/routesManager'

import * as S from 'pages/collections/details/components/sidebarMenu/SidebarMenu.styled'

export type CollectionDetailsTabType = keyof typeof CollectionDetailsTabs
export const collectionDetailsTabIds = ['PRODUCTS', 'AVAILABILITY'] as CollectionDetailsTabType[]

export const SidebarMenu = () => {
  const { t } = useTranslation(['collections'])
  const navigate = useNavigate()
  const { hash } = useLocation()
  const { collection, isCollectionLoading } = useCollectionData()
  const activeTab = (hash.slice(1) as CollectionDetailsTabs) || CollectionDetailsTabs.PRODUCTS

  const tabs = useMemo(
    () =>
      collectionDetailsTabIds.map((tabId, i) => ({
        label: t(
          `collections|collection_details.sidebar_menu.${
            collectionDetailsTabIds[i].toLowerCase() as Lowercase<CollectionDetailsTabType>
          }`,
        ),
        isActive: activeTab === tabId,
        id: tabId,
      })),
    [t, activeTab],
  )

  const handleItemClick = ({ detail }: WppListItemCustomEvent<ListItemChangeEventDetail>) => {
    const tabId = detail.value as CollectionDetailsTabType
    navigate({
      pathname: RoutesManager.collections.details.getURL({ collectionId: collection?.id! }),
      hash: tabId,
    })
  }

  if (isCollectionLoading) {
    return (
      <Flex direction="column" gap={6} data-testid="sidebar-menu-skeleton">
        {Array.from({ length: collectionDetailsTabIds.length }).map((_, i) => (
          <WppSkeleton width={204} height={32} key={i} />
        ))}
      </Flex>
    )
  }

  return (
    <S.SidebarMenu gap={6} data-testid="sidebar-menu">
      {tabs.map(({ id, isActive, label }) => (
        <S.MenuItem
          key={id}
          value={id}
          checked={isActive}
          onWppChangeListItem={handleItemClick}
          data-testid={`${id}-sidebar-menu-item`}
        >
          <WppTypography type={isActive ? 's-midi' : 's-body'} slot="label">
            {label}
          </WppTypography>
        </S.MenuItem>
      ))}
    </S.SidebarMenu>
  )
}
