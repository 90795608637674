import { WppTypography } from '@platform-ui-kit/components-library-react'
import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { forwardRef, ReactNode } from 'react'

import { AgGridTable } from 'ui-base/agGridTable/AgGridTable'

import * as S from 'ui-base/dataTable/DataTable.styled'

export interface CustomParams {
  cell: ReactNode
  isEmptyValue?: boolean
  customEmptyValue?: ReactNode
  customLoading?: ReactNode
  isLoading?: boolean
  isFetchingNextPage?: boolean
}

export interface ColumnDefs<T> extends ColDef {
  cellRendererParams?: (arg: ICellRendererParams<T> & { data: T extends any[] ? T[number] : T }) => CustomParams
}

export type CustomCellRenderer = ColDef['cellRenderer'] & CustomParams

export interface DataTableProps extends GridOptions {
  isLoading?: boolean
  isInitialLoading?: boolean
  itemsPerPage?: number
  emptyState?: JSX.Element
  isPreviousData?: boolean
  isFetchingNextPage?: boolean
  showSkeleton?: boolean
  loadingSkeletonRows?: number
  'data-testid'?: string
}

export const TABLE_ROW_HEIGHT = 48
export const ITEMS_PER_PAGE = 10
export const LOADING_SKELETON_ROWS = 4

export const DataTable = forwardRef<AgGridReact, DataTableProps>(
  (
    {
      isLoading = false,
      isInitialLoading = false,
      itemsPerPage = ITEMS_PER_PAGE,
      isPreviousData,
      isFetchingNextPage,
      loadingSkeletonRows = LOADING_SKELETON_ROWS,
      emptyState,
      columnDefs,
      rowHeight = TABLE_ROW_HEIGHT,
      rowData,
      'data-testid': dataTestId,
      ...props
    }: DataTableProps,
    ref,
  ) => {
    const isTableLoading = isLoading || isInitialLoading
    const showEmptyState = !rowData?.length && !isTableLoading
    return (
      <S.TableBody direction="column" data-testid={dataTestId}>
        {!showEmptyState ? (
          <AgGridTable
            isPreviousData={isPreviousData}
            itemsPerPage={itemsPerPage}
            rowHeight={rowHeight}
            columnDefs={columnDefs}
            rowData={rowData}
            ref={ref}
            domLayout="autoHeight"
            isFetchingNextPage={isFetchingNextPage}
            loadingSkeletonRows={loadingSkeletonRows}
            {...props}
          />
        ) : (
          emptyState
        )}
      </S.TableBody>
    )
  },
)

export const TableEmptyCell = <WppTypography type="s-body">&mdash;</WppTypography>
