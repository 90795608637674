import styled from '@emotion/styled'

import { Tooltip } from 'ui-base/tooltip/Tooltip'

export const StyledTooltip = styled(Tooltip)`
  display: inline-flex;
  overflow: hidden;
  width: inherit;
  &::part(anchor) {
    overflow: hidden;
    display: inline-flex;
  }
  &::part(tooltip-content) {
    white-space: normal;
    width: 100%;
  }
`
