import { GridOptions } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { forwardRef, useState } from 'react'
import 'ag-grid-community/styles/ag-grid.css'
import '@platform-ui-kit/components-library/dist/collection/ag-theme-wpp.css'

import { cellRenderer } from 'ui-base/agGridTable/components/cellRenderer'
import { HeaderCell } from 'ui-base/agGridTable/components/HeaderCell'
import { useTableEmptyRows } from 'ui-base/agGridTable/utils/useTableEmptyRows'

import * as S from 'ui-base/agGridTable/AgGridTable.styled'

interface Props extends GridOptions {
  itemsPerPage?: number
  isPreviousData?: boolean
  showSkeleton?: boolean
  isFetchingNextPage?: boolean
  loadingSkeletonRows?: number
}

export const AgGridTable = forwardRef<AgGridReact, Props>(
  (
    {
      rowData,
      defaultColDef,
      isPreviousData,
      itemsPerPage,
      showSkeleton,
      isFetchingNextPage,
      loadingSkeletonRows = 4,
      ...props
    }: Props,
    ref,
  ) => {
    const [isLoading, setIsLoading] = useState(true)
    const rows = useTableEmptyRows({
      isPreviousData,
      rowData,
      itemsPerPage: itemsPerPage || props.paginationPageSize,
      showSkeleton,
      isFetchingNextPage,
      loadingSkeletonRows,
    })
    return (
      <S.TableWrapper isLoading={isLoading} className="ag-theme-wpp">
        <AgGridReact
          ref={ref}
          onColumnResized={event => event.finished && setIsLoading(false)}
          defaultColDef={{
            headerComponent: HeaderCell,
            cellRenderer,
            suppressMovable: true,
            flex: 1,
            ...defaultColDef,
          }}
          enableCellTextSelection={!!rowData?.length}
          rowData={rows}
          {...props}
        />
      </S.TableWrapper>
    )
  },
)
