import { devHubApi } from 'api'
import { CollectionDTO } from 'types/collections/collection'
import { ProductTenant } from 'types/products/tenant'

export interface ActivateCollectionTenantParams {
  collectionId: CollectionDTO['id']
  tenantId: ProductTenant['tenantId']
}

export const activateCollectionTenant = ({ collectionId, tenantId }: ActivateCollectionTenantParams) =>
  devHubApi.post(`/collections/${collectionId}/availability/${tenantId}/activate`)
