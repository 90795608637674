import {
  WppActionButton,
  WppIconMore,
  WppListItem,
  WppMenuContext,
  WppTag,
  WppTypography,
  WppIconTick,
} from '@platform-ui-kit/components-library-react'
import { AgGridReact } from 'ag-grid-react'
import { forwardRef, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { useProductCategories } from 'hooks/useProductCategories'
import { useCollectionProductsLoader } from 'pages/collections/details/tabs/products/hooks/useCollectionProductsLoader'
import { CollectionProductDTO } from 'types/collections/collection'
import { MayBeNull } from 'types/common/utils'
import { ProductType, ProductTypeLabel } from 'types/products/enums'
import { InfiniteTable, ColDef } from 'ui-base/dataTable/InfiniteTable/InfiniteTable'
import { TableInfiniteOnLoadError } from 'ui-base/dataTable/InfiniteTable/utils'
import { Flex } from 'ui-base/flex/Flex'
import { ProductTypeIcon } from 'ui-base/svgIcons/productTypeIcon/ProductTypeIcon'
import { Tooltip } from 'ui-base/tooltip/Tooltip'
import { Truncate } from 'ui-base/truncate/Truncate'
import { ProductOnboardingTag } from 'ui-shared/productOnboardingTag/ProductOnboardingTag'
import { handleApiErrors } from 'utils/api'
import { getFullProductType } from 'utils/product'
import { RoutesManager } from 'utils/routesManager'

import * as S from 'pages/collections/details/tabs/products/components/collectionProductsTable/CollectionProductsTable.styled'

export const CollectionProductsTable = forwardRef<AgGridReact>((_, ref) => {
  const { t } = useTranslation(['collections', 'products'])
  const navigate = useNavigate()
  const { collectionId } = useParams()
  const [error, setError] = useState<MayBeNull<unknown>>(null)
  const { categoriesConfig, subcategoriesMap } = useProductCategories()
  const { loader } = useCollectionProductsLoader({ collectionId: collectionId! })

  const columnDefs = useMemo<ColDef<CollectionProductDTO>[]>(
    () => [
      {
        flex: 0.21,
        colId: 'name',
        headerName: t('collections|collection_details.products_table.column_names.name_and_studio'),
        cellRenderer: ({ data }) => {
          const { logoMeta, categoryLogoUrl, productType, applicationCategory, productName } =
            data as CollectionProductDTO

          const categoryName = categoriesConfig?.[applicationCategory?.category!]?.name
          const subcategoryName =
            applicationCategory?.subcategory && subcategoriesMap?.[applicationCategory?.subcategory!]?.name
          const fullCategoryText = !subcategoryName ? categoryName : `${categoryName}, ${subcategoryName}`

          return (
            <Flex gap={10} align="center">
              <S.ProductLogo
                productType={productType}
                logoMeta={logoMeta!}
                categoryLogoUrl={categoryLogoUrl!}
                data-testid="product-logo"
              />
              <S.NameColumnInner direction="column" justify="center">
                <Tooltip show showOnOverflow text={productName}>
                  <S.ProductName type="s-body">{productName}</S.ProductName>
                </Tooltip>
                {!!applicationCategory && (
                  <Tooltip show showOnOverflow text={fullCategoryText}>
                    <S.ProductCategory type="xs-body">{fullCategoryText}</S.ProductCategory>
                  </Tooltip>
                )}
              </S.NameColumnInner>
            </Flex>
          )
        },
      },
      {
        flex: 0.21,
        colId: 'status',
        headerName: t('collections|collection_details.products_table.column_names.status'),
        cellRenderer: ({ data }) => <ProductOnboardingTag status={data?.publishOnboardingStatus!} />,
      },
      {
        flex: 0.21,
        colId: 'productType',
        headerName: t('collections|collection_details.products_table.column_names.type'),
        cellRenderer: ({ data }) => {
          const productType = getFullProductType(data?.productType!, data?.noCodeAppType!)

          return (
            <Flex gap={4} align="center">
              <ProductTypeIcon size={20} productType={productType} iconColor="var(--wpp-icon-color)" />
              <WppTypography type="s-body">
                {t(`products|product_type_names.${ProductTypeLabel[productType]!}`)}
              </WppTypography>
            </Flex>
          )
        },
      },
      {
        flex: 0.17,
        colId: 'versionName',
        headerName: t('collections|collection_details.products_table.column_names.version'),
        cellRendererParams: ({ data }) => {
          return {
            isEmptyValue: !data?.versionName || data?.productType === ProductType.NO_CODE_APPLICATION,
            cell: (
              <Tooltip show showOnOverflow text={data?.versionName}>
                <Truncate type="s-body">{data?.versionName}</Truncate>
              </Tooltip>
            ),
          }
        },
      },
      {
        flex: 0.13,
        colId: 'readyForOs',
        headerName: t('collections|collection_details.products_table.column_names.activation_ready'),
        cellRenderer: ({ data }) => {
          const isReadyForOs = data?.readyForOs!
          return (
            <WppTag
              variant={isReadyForOs ? 'positive' : 'neutral'}
              label={t(
                `collections|collection_details.products_table.activation_ready_values.${
                  isReadyForOs ? 'ready' : 'not_ready'
                }`,
              )}
              data-testid="collection-product-ready-for-os"
            >
              {isReadyForOs ? (
                <WppIconTick slot="icon-start" />
              ) : (
                <S.CircleWrapper align="center" justify="center" slot="icon-start">
                  <S.Circle />
                </S.CircleWrapper>
              )}
            </WppTag>
          )
        },
      },
      {
        minWidth: 70,
        maxWidth: 70,
        colId: 'actions',
        cellRenderer: ({ data }) => (
          <S.ActionsWrapper onClick={e => e.preventDefault()}>
            <WppMenuContext listWidth="156px" dropdownConfig={{ placement: 'bottom-end' }}>
              <WppActionButton variant="secondary" slot="trigger-element" data-testid="three-dots-menu-button">
                <WppIconMore direction="horizontal" color="var(--wpp-grey-color-600)" />
              </WppActionButton>
              <div>
                {data?.productType === ProductType.NATIVE_APPLICATION && (
                  <WppListItem
                    onWppChangeListItem={() =>
                      navigate(
                        RoutesManager.products.versions.update.getURL({
                          versionId: data?.versionId!,
                          productId: data?.productId!,
                        }),
                      )
                    }
                  >
                    <p slot="label">
                      {t('collections|collection_details.products_table.context_menu_actions.manage_version')}
                    </p>
                  </WppListItem>
                )}
                <WppListItem
                  onWppChangeListItem={() =>
                    navigate(RoutesManager.products.root.getURL({ productId: data?.productId! }))
                  }
                >
                  <p slot="label">
                    {t('collections|collection_details.products_table.context_menu_actions.manage_product')}
                  </p>
                </WppListItem>
              </div>
            </WppMenuContext>
          </S.ActionsWrapper>
        ),
      },
    ],
    [t, categoriesConfig, subcategoriesMap, navigate],
  )

  const onLoadError: TableInfiniteOnLoadError = useCallback(error => setError(error), [setError])

  if (!!error) {
    return handleApiErrors({ error })
  }

  return (
    <S.TableWrapper grow={1}>
      <InfiniteTable
        ref={ref}
        loader={loader}
        getRowId={({ data }) => data?.versionId}
        rowHeight={60}
        columnDefs={columnDefs}
        onLoadError={onLoadError}
        data-testid="collection-products-table"
      />
    </S.TableWrapper>
  )
})
