import { WppButton } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { EmptyState } from 'ui-base/emptyState/EmptyState'

interface Props {
  isSearchApplied: boolean
  handleClickResetButton: () => void
}

export const TableEmptyState = ({ isSearchApplied, handleClickResetButton }: Props) => {
  const { t } = useTranslation(['collections'])

  return (
    <EmptyState
      message={{
        title: t(`collections|availability${isSearchApplied ? '.search' : ''}.empty_state.title`),
        subtitle: t(`collections|availability${isSearchApplied ? '.search' : ''}.empty_state.subtitle`),
      }}
      margin="120px auto"
      data-testid="availability-table-empty-state"
    >
      {isSearchApplied && (
        <WppButton
          variant="secondary"
          onClick={handleClickResetButton}
          data-testid="reset-other-workspaces-search-button"
        >
          {t('collections|availability.search.empty_state.button')}
        </WppButton>
      )}
    </EmptyState>
  )
}
