import { devHubApi } from 'api'
import { PageSizes } from 'constants/pageSizes'
import { ApiRequestPaginationParameters, Paginated } from 'types/api/api'
import { CollectionDTO } from 'types/collections/collection'
import { ProductTenant } from 'types/products/tenant'

type Params = ApiRequestPaginationParameters & {
  collectionId?: CollectionDTO['id']
  searchPhrase?: string
}

export const fetchInfiniteCollectionTenantsAvailabilityApi = ({
  collectionId,
  page,
  size = PageSizes.PAGINATED_LOAD_ALL,
  searchPhrase,
}: Params) =>
  devHubApi.get<Paginated<ProductTenant>>(`/collections/${collectionId}/availability`, {
    params: { page, size, ...(!!searchPhrase && { searchPhrase }) },
  })
