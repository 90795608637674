import { fetchInfiniteCollectionTenantsAvailabilityApi } from 'api/collections/fetchers/fetchInfiniteCollectionTenantsAvailabilityApi'
import { createUseInfiniteQuery } from 'api/common/createUseInfiniteQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useInfiniteCollectionTenantsAvailabilityApi = createUseInfiniteQuery({
  queryKey: ApiQueryKeys.COLLECTION_TENANTS_AVAILABILITY,
  fetcher: fetchInfiniteCollectionTenantsAvailabilityApi,
  selector: res => res?.pages?.flatMap(page => page.data?.content) || [],
  getNextPageParam: lastPage => (lastPage?.data?.last ? undefined : lastPage?.data?.number + 1),
})
