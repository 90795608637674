import { WppIconTick, WppTag, WppTypography } from '@platform-ui-kit/components-library-react'
import { ComponentProps } from 'react'

import { Flex } from 'ui-base/flex/Flex'

import * as S from 'ui-shared/completionCheckbox/CompletionCheckbox.styled'

interface Props extends ComponentProps<typeof Flex> {
  checked: boolean
  title?: string
  tagLabel?: string
  testIdTitle?: string
}

export const CompletionCheckbox = ({ checked = false, title, tagLabel, testIdTitle, children, ...rest }: Props) => (
  <Flex gap={8} align="center" data-testid={`completed-${checked}-title-${testIdTitle || title}`} {...rest}>
    {checked ? (
      <WppIconTick color="var(--wpp-success-color-400)" />
    ) : (
      <S.CircleWrapper align="center" justify="center">
        <S.Circle />
      </S.CircleWrapper>
    )}
    {title && (
      <WppTypography type="s-body" className="title" data-testid={`completed-title-${testIdTitle || title}`}>
        {' '}
        {title}
      </WppTypography>
    )}
    {tagLabel && <WppTag label={tagLabel} categoricalColorIndex={1} />}
    {children}
  </Flex>
)
