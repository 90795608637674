import { WppSkeleton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { useCollectionData } from 'hooks/useCollectionData'
import { CollectionDetailsTabs } from 'pages/collections/details/CollectionDetailsPage'
import { Flex } from 'ui-base/flex/Flex'
import { CompletionCheckbox } from 'ui-shared/completionCheckbox/CompletionCheckbox'

import * as Styles from 'pages/collections/details/tabs/availability/components/enaiblingAvailabilityInfoMessage/EnablingAvailabilityInfoMessage.styled'
import * as S from 'ui-shared/infoMessage/InfoMessage.styled'

export const EnablingAvailabilityInfoMessage = ({ ...rest }) => {
  const { collection, isCollectionLoading } = useCollectionData()
  const { t } = useTranslation(['collections'])
  const isProducts = !!collection?.elements?.length
  const isProductsNotReadyForOS = collection?.elements?.some(product => !product.readyForOs)

  if (isCollectionLoading) {
    return (
      <Styles.InfoMessage
        title={t('collections|availability.enabling_availability_info_message.title')}
        show={!collection?.readyForOs}
        size="m"
        {...rest}
        data-testid="enabling-availability-info-message-skeleton"
      >
        <Flex direction="column" gap={7}>
          <WppSkeleton width="70%" height={22} />
          <WppSkeleton width="70%" height={22} />
        </Flex>
      </Styles.InfoMessage>
    )
  }

  return (
    <Styles.InfoMessage
      title={t('collections|availability.enabling_availability_info_message.title')}
      show={!collection?.readyForOs}
      size="m"
      {...rest}
      data-testid="enabling-availability-info-message"
    >
      <Flex direction="column" gap={7}>
        <CompletionCheckbox checked={isProducts} testIdTitle="emptyProductsList">
          <Flex wrap="wrap" gap={3}>
            <WppTypography type="s-body" className="title" data-testid="completed-title-emptyProductsList">
              {t('collections|availability.enabling_availability_info_message.empty_products_list_description')}
            </WppTypography>
            <S.Link
              to={`#${CollectionDetailsTabs.PRODUCTS}`}
              data-testid="enabling-availability-info-message-link-empty-products-list"
            >
              <WppTypography type="s-body">
                {t('collections|availability.enabling_availability_info_message.empty_products_list_link')}
              </WppTypography>
            </S.Link>
          </Flex>
        </CompletionCheckbox>

        {isProducts && (
          <CompletionCheckbox checked={!isProductsNotReadyForOS} testIdTitle="productsReadyForOs">
            <Flex wrap="wrap" gap={3}>
              <WppTypography type="s-body" className="title" data-testid="completed-title-productsReadyForOs">
                {t('collections|availability.enabling_availability_info_message.products_ready_for_os_description')}
              </WppTypography>
              <S.Link
                to={`#${CollectionDetailsTabs.PRODUCTS}`}
                data-testid="enabling-availability-info-message-link-products-ready-for-os"
              >
                <WppTypography type="s-body">
                  {t('collections|availability.enabling_availability_info_message.products_ready_for_os_link')}
                </WppTypography>
              </S.Link>
            </Flex>
          </CompletionCheckbox>
        )}
      </Flex>
    </Styles.InfoMessage>
  )
}
