import { MayBeNull } from '@wpp-open/core'
import { useMemo } from 'react'

import { Delays } from 'constants/delays'
import { useDebouncedValue } from 'hooks/useDebouncedValue'
import { ITEMS_PER_PAGE } from 'ui-base/dataTable/DataTable'

interface Props {
  isPreviousData?: boolean
  rowData?: MayBeNull<any[]>
  itemsPerPage?: number
  showSkeleton?: boolean
  isFetchingNextPage?: boolean
  loadingSkeletonRows: number
}

export const useTableEmptyRows = ({
  isPreviousData,
  rowData,
  itemsPerPage = ITEMS_PER_PAGE,
  showSkeleton,
  isFetchingNextPage,
  loadingSkeletonRows,
}: Props) => {
  const skeletonRowsLen = rowData?.length || itemsPerPage
  const skeletonRows = useMemo(() => Array(skeletonRowsLen).fill({ __loading: true }), [skeletonRowsLen])
  const loaderSkeletonRows = useMemo(() => Array(loadingSkeletonRows).fill({ __loading: true }), [loadingSkeletonRows])
  const isEmpty = !rowData?.length
  const debouncedPrevData = useDebouncedValue(isPreviousData, Delays.TableSkeleton)
  const showSkeletonInner = showSkeleton || (debouncedPrevData && isPreviousData)
  return isEmpty || showSkeletonInner ? skeletonRows : [...rowData, ...(isFetchingNextPage ? loaderSkeletonRows : [])]
}
