import { devHubApi } from 'api'
import { CollectionDTO } from 'types/collections/collection'
import { ProductTenant } from 'types/products/tenant'

interface Params {
  collectionId: CollectionDTO['id']
}

export const fetchCollectionTenantAvailabilityApi = ({ collectionId }: Params) =>
  devHubApi.get<ProductTenant>(`/collections/${collectionId}/availability/collection-tenant`)
