import { styled } from '@mui/material/styles'

import { Flex } from 'ui-base/flex/Flex'

export const Circle = styled('div')`
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 1px dashed #8b919a;
  min-width: 0;
`

export const CircleWrapper = styled(Flex)`
  width: 20px;
  height: 20px;
`
