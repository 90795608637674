import { QueryKey } from '@tanstack/query-core/src/types'
import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useCallback } from 'react'

import { Paginated } from 'types/api/api'

type Response<T> = AxiosResponse<T> | undefined
type PaginatedResponse<T> = InfiniteData<AxiosResponse<Paginated<T>>> | undefined
interface Props {
  queryKey: QueryKey
}
interface RemoveItemsArgs<T> {
  matchItems?: (staleItem: T) => boolean
  response?: Response<T>
}
interface UpdateItemsArgs<T> {
  updateMatchedItems?: (staleItem: T, newItem?: T) => Partial<T> | undefined
  response?: Response<T>
}

export const useQueryCacheUpdate = <T>({ queryKey }: Props) => {
  const queryClient = useQueryClient()

  const createItem = useCallback(
    (response: Response<T>) =>
      queryClient.setQueryData(queryKey, (oldData: PaginatedResponse<T>) => {
        if (!oldData) return undefined
        const [firstPage, ...restPages] = oldData.pages
        return {
          ...oldData,
          pages: [
            {
              ...firstPage,
              data: {
                ...firstPage.data,
                content: [response?.data!, ...firstPage.data.content], // add new item to the top of first page
              },
            },
            ...restPages,
          ],
        }
      }),
    [queryClient, queryKey],
  )

  const updateItems = useCallback(
    ({ updateMatchedItems, response }: UpdateItemsArgs<T>) =>
      queryClient.setQueryData(queryKey, (oldData: PaginatedResponse<T>) => {
        if (!oldData) return undefined
        return {
          ...oldData,
          pages: oldData.pages.map(page => ({
            ...page,
            data: {
              ...page.data,
              content: page.data.content.map(item => {
                const partialUpdated = updateMatchedItems?.(item, response?.data)
                return partialUpdated ? { ...item, ...partialUpdated } : item
              }),
            },
          })),
        }
      }),
    [queryKey, queryClient],
  )

  const removeItems = useCallback(
    ({ matchItems }: RemoveItemsArgs<T>) =>
      queryClient.setQueryData(queryKey, (oldData: PaginatedResponse<T>) => {
        if (!oldData) return undefined
        return {
          ...oldData,
          pages: oldData.pages.map(page => ({
            ...page,
            data: {
              ...page.data,
              content: page.data.content.filter(item => !matchItems?.(item)), // find items to remove
            },
          })),
        }
      }),
    [queryClient, queryKey],
  )

  return {
    createItem,
    updateItems,
    removeItems,
  }
}
