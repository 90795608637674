import styled from '@emotion/styled'
import { WppCard, WppInput } from '@platform-ui-kit/components-library-react'

export const CollectionAvailabilityCard = styled(WppCard)`
  height: 100%;
  width: 100%;
  min-height: 300px;

  &::part(card) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ag-root.ag-unselectable {
    user-select: auto;
  }

  .ag-overlay-wrapper {
    pointer-events: auto;
  }
`

export const SearchInput = styled(WppInput)`
  width: 287px;
`
